import React, { useEffect, useState } from 'react';
import { queryGraph } from './api';
import './index.css';

const Detail = () => {
  const [data, setData] = useState({});

  const getUrlParams = (key) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    return params.get(key);
  };

  useEffect(() => {
    const creditorId = getUrlParams('creditorId');
    const debtorId = getUrlParams('debtorId');
    queryGraph({
      creditorId: creditorId,
      debtorId: debtorId
    }).then((res) => {
      setData(res.data);
    })
  }, []);

  return (
    <div className="details">
      <div className="details-item">
        <div className="form-lable">债权公司名称:</div>
        <div className="form-value">{ data?.creditor?.companyFullName || '--' }</div>
      </div>
      <div className="details-item">
        <div className="form-lable">债务公司名称:</div>
        <div className="form-value">{ data?.debtor?.companyFullName || '--' }</div>
      </div>
      <div className="details-item">
        <div className="form-lable">金额:</div>
        <div className="form-value">{ data?.price || '--' }</div>
      </div>
      <div className="details-item">
        <div className="form-lable">文书号:</div>
        <div className="form-value">{ data?.wenshuNumber || '--' }</div>
      </div>
      <div className="details-item">
        <div className="form-lable">法院:</div>
        <div className="form-value">{ data?.courtName || '--' }</div>
      </div>
      <div className="details-item">
        <div className="form-lable">年份:</div>
        <div className="form-value">{ data?.year || '--' }</div>
      </div>
      {
        data.courtUrl ? (<div className="details-item">
        <div className="form-lable">文书地址:</div>
        <a href={ data?.courtUrl || '--' }>点击跳转</a>
        </div>) : null
      }
      
    </div>
  );
}

export default Detail;