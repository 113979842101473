import axios from "axios";
import { Toast } from 'antd-mobile';

axios.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  console.log()
  if (response.data.code !== 200) {
    Toast.show({
      content: response.data?.message
    });
  }
  return response?.data;
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  Toast.show({
    content: error?.message
  });
  
  return Promise.reject(error);
});

export default axios;
