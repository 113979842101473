import axios from "../../utils/axios";

export const upload = (data) => {
  return axios.post('/api/upload/file', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadGraph = (data) => {
  return axios.post('/api/upload/graph', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
