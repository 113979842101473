import React, { useEffect, useState, useRef } from 'react';
import EChartsReact from 'echarts-for-react';
import { useNavigate } from 'react-router-dom';
import { UndoOutline } from 'antd-mobile-icons'

import './index.css';
import { getCycle } from './api';


const RelationLoop = () => {

  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState({});

  const navigate = useNavigate();

  const jump = (path) => {
    navigate(path);
  };

  const getUrlParams = (key) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    return params.get(key);
  };

  const createCycleOption = (data) => {
    const currentCompanyId = getUrlParams('companyId');
    var currentCompany = {};

    // 合并二维数组中的数组数据
    let combinedCompany = data.reduce((accumulator, current) => {
        return accumulator.concat(current.companyList);
    }, []);
  
    // 按id进行去重
    let resultCompanyMap = new Map();
    combinedCompany.forEach(item => {
        resultCompanyMap.set(item.id, item);
    });
  
    // 将Map转换回数组
    let uniqueCompanyArray = Array.from(resultCompanyMap.values());

    const nodes = uniqueCompanyArray.map((node,index) => {
      const companyId = node.id.toString();
      var size = 30
      if (companyId === currentCompanyId) {
        currentCompany = node;
        size = 50
      }

      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const category = letters[index % 26];

      return {
        id: companyId,
        name: node.companyFullName.match(/.{1,6}/g).join('\n'),
        category: category,
        value: node.id,
        label: {
          show: true
        },
        symbolSize: size,
      }
    });

    

    // 合并二维数组中的数组数据
    let relationCombinedCompany = data.reduce((accumulator, current) => {
        return accumulator.concat(current.relationList);
    }, []);

    // 按照id + name的方式去重
    let relationResultMap = new Map();
    let relationUniqueArray = relationCombinedCompany.reduce((accumulator, current) => {
        const key = current.creditor.id.toString()  + ">" + current.debtor.id.toString();
        if (!relationResultMap.has(key)) {
            relationResultMap.set(key, current);
            accumulator.push(current);
        }
        return accumulator;
    }, []);


    const links = relationUniqueArray.map((link) => {

      let detailUrl = "/detail?creditorId=" + link.creditor.id.toString() + "&debtorId=" + link.debtor.id.toString()
      let content =  "债务金额：" + link.price + "<br />" + 
                      "债权方：" + link.creditor.companyFullName + "<br />" + 
                      "债务方：" + link.debtor.companyFullName + "<br />" + 
                      "文书编号：" + link.wenshuNumber + "<br />" + 
                      "法院：" + link.courtName + "<br />" + 
                      "年份：" + link.year + "<br />"  +
                      // "<a href='"+ detailUrl + "'>查看债务详情</a><br />" + 
                      `<a id="viewDetail" creditorId=${link.creditor.id} debtorId=${link.debtor.id}>查看债务详情</a ><br />` + 
                      (link.courtUrl != null ? "<a href='" + link.courtUrl + "'>查看法院文书</a>" : "" )
      return {
        source: link.creditor.id.toString(),
        target: link.debtor.id.toString(),
        content: content,
        price: link.price,
        label: {
          show: true
        }
      }
    });
    let relationCount = relationUniqueArray.length;

    const option = {
      title: {
        text: currentCompany.companyFullName + '闭环关系链',
        subtext: '共找到' + relationCount.toString() + '条关系; 债务闭环数:' + data.length.toString() + '条',
        top: 'top',
        left: 'left'
      },
      tooltip: {
        show: true,
        formatter: function (params) {
          if (params.componentType === 'series') {
            if (params.dataType === 'node') {
              return params.data.name; // 自定义节点点击后的弹框内容信息
            } else if (params.dataType === 'edge') {
              return '' + params.data.content; // 自定义边（连线）点击后的弹框内容信息
            }
          }
        },
        position: function (point, params, dom, rect, size) {
          var obj = {top: point[0]};
          let left = (size.viewSize[0] - size.contentSize[0])/2
          obj['left'] = left;
          obj['top'] = point[1] + 70;
          var element = document.getElementById('viewDetail');
          element?.addEventListener('click', () => {
            var creditorId = element.getAttribute('creditorid');
            var debtorId = element.getAttribute('debtorid');
            jump(`/detail?creditorId=${creditorId}&debtorId=${debtorId}`);
          });
          return obj;
        },
        enterable: true,
      },
      animationDuration: 1500,
      animationEasingUpdate: 'quinticInOut',
      series: [
        {
          selectedMode: 'single',
          select: {
            label: {
              show: true
            }
          },
          edgeLabel: {//边的设置
            show: true,
            position: "middle",
            fontSize: 12,
            formatter: (params) => {
              return params.data.price;
            },
          },
          edgeSymbol: ['none', 'arrow'],
          type: 'graph',
          layout: 'force',
          symbolSize: 40,
          roam: false,
          lineStyle: {
            color: 'source',
            curveness: 0.2,
          },
          emphasis: {
            scale: true,
            focus: 'adjacency',
            label: {
              show: true,
              position: 'top',
              fontSize: 20
            },
            edgeLabel: { 
                show: false,
            },
            lineStyle: { 
              width: 3,
            }
          },
          label: {
            show: true,
            position: 'middle',
            formatter: '{b}',
            borderWidth: 100
          },
          force : { //力引导图基本配置
            //initLayout: ,//力引导的初始化布局，默认使用xy轴的标点
            repulsion : 250,//节点之间的斥力因子。支持数组表达斥力范围，值越大斥力越大。
            gravity : 0.1,//节点受到的向中心的引力因子。该值越大节点越往中心点靠拢。
            edgeLength :[1,250],//边的两个节点之间的距离，这个距离也会受 repulsion。[10, 50] 。值越小则长度越长
            layoutAnimation : false //因为力引导布局会在多次迭代后才会稳定，这个参数决定是否显示布局的迭代动画，在浏览器端节点数据较多（>100）的时候不建议关闭，布局过程会造成浏览器假死。                         
          },
          data: nodes,
          links: links,
          categories: [
            {
              "name": "A"
            },
            {
              "name": "B"
            },
            {
              "name": "C"
            },
            {
              "name": "D"
            },
            {
              "name": "E"
            },
            {
              "name": "F"
            },
            {
              "name": "G"
            },
            {
              "name": "H"
            },
            {
              "name": "I"
            },
            {
              "name": "J"
            },
            {
              "name": "K"
            },
            {
              "name": "L"
            },
            {
              "name": "M"
            },
            {
              "name": "N"
            },
            {
              "name": "O"
            },
            {
              "name": "P"
            },
            {
              "name": "Q"
            },
            {
              "name": "R"
            },
            {
              "name": "S"
            },
            {
              "name": "T"
            },
            {
              "name": "U"
            },
            {
              "name": "V"
            },
            {
              "name": "W"
            },
            {
              "name": "X"
            },
            {
              "name": "Y"
            },
            {
              "name": "Z"
            }
            
            
          ]
        }
      ]
    }
    return option;
   };

  useEffect(() => {
    const companyId = getUrlParams('companyId');
    const params = { "companyId": companyId}
    getCycle(params).then(res => {
      const option = createCycleOption(res.data);
      setOption(option);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })
  }, []);


  return (<div className="relation-loop">
    <UndoOutline className='refresh' onClick={() => { window.location.reload(); }} />
    { loading ? <div className="loading">加载中...</div> : ''}
    { option === null ? '' : <EChartsReact option={option} style={{ height: '100%' }}/>}
  </div>)
};

export default RelationLoop;