import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce, uniqBy } from 'lodash';
import { SearchBar, Toast } from 'antd-mobile';
import { getSearchResult } from './api';

import './index.css';

const hotList = ['阿里巴巴', '京东', '美团', '网易'];
const Search = () => {
  let composition = false;
  const [recentlyList, setRecentlyList] = useState([]);
  const [searchResult, setSearchResult] = useState();
  const [isShowPop, setIsShowPop] = useState(false);
  const navigate = useNavigate();
  const searchRef = useRef(null)


  useEffect(() => {
    let newRecentlyList = localStorage.getItem('AppRecentlyList');
    if (newRecentlyList) {
      try {
        setRecentlyList(JSON.parse(newRecentlyList));
      } catch{
        console.log('解析错误')
      }
    }
  }, []);


  const jump = (path) => {
    navigate(path);
  };

  const searchHandler = debounce((value) => {
    // 搜索字符大于1才请求
    if (value && value?.length > 1 && !composition) {
      getSearchResult({
        keyword: value,
        page: 0,
        pageSize: 10
      }).then((res) => {
        setSearchResult(res.data?.companyList || []);
      });
    }
    setIsShowPop(value ? true : false);
  }, 500);

  const highlightMatch = (text, searchTerm) => {
    const regex = new RegExp(searchTerm, 'gi');
    const highlightedText = text.replace(regex, (match) => {
      return `<span class="highlight">${match}</span>`;
    });
    return highlightedText;
  };

  const clickSearch = () => {
    const value = searchRef?.current?.nativeElement?.value;
    if (value && value?.length > 1) {
      jump(`/searchResult?keyword=${value}`);
    } else {
      Toast.show({
        content: '更全的信息有助于搜索到更准确的结果'
      });
    }
  };

  const queryCompany = (item) => {
    const newRecentlyList = JSON.stringify(uniqBy([...recentlyList, item], 'id'));
    localStorage.setItem('AppRecentlyList', newRecentlyList);
    jump(`/relationChain?companyId=${item.id}`);
  };

  const cleanHistory = () => { 
    localStorage.clear('AppRecentlyList');
    setRecentlyList([]);
  }

  return (
    <div className="search">
      <div className="search-box">
        <SearchBar
          ref={searchRef}
          className="search-input"
          placeholder="请输入企业名等关键字进行搜索"
          onChange={(value) => { searchHandler(value); }}
          onCompositionStart={() => {
            composition = true;
          }}
          onCompositionEnd={() => {
            composition = false;
          }}
        />
        <div className="search-btn" onClick={() => { clickSearch(); }}>搜索</div>
        {
          isShowPop && <div className="serch-pop">
            {
              searchResult && searchResult?.length > 0  ? searchResult.map((item) => {
                return (
                  <div
                    className="serch-pop-item"
                    key={item.id}
                    onClick={() => { queryCompany(item); }}
                  >
                   <div dangerouslySetInnerHTML={{ __html: highlightMatch(item?.companyFullName, searchRef?.current?.nativeElement?.value)}} />
                  </div>
                );
              }) : <div className="serch-pop-item">无选项</div>
            }
            <div className="serch-pop-help" onClick={() => { jump('/create'); }}>没有找到我要的公司，点击上链</div>
          </div>
        }
      </div>
      {
        recentlyList && recentlyList?.length > 0 &&  <div className="recently-search">
          <div className='recently-search-top'>
            <div className="recently-search-title">最近搜索</div>
            <div className="recently-search-clean" onClick={() => { cleanHistory()} }>清空</div>
          </div>
          
          <div className="recently-search-box" >
            {recentlyList.map((item) => {
              return (
                <div
                  className="recently-search-item"
                  key={item.id}
                  onClick={() => { queryCompany(item); }} 
                >
                  {item.companyFullName}
                </div>
              );
            })}
          </div>
        </div>
      }
      {
        hotList && hotList?.length > 0 &&  <div className="hot-search">
          <div className="hot-search-title">热门搜索</div>
          <div className="hot-search-box" >
            {hotList.map((item, index) => {
              return (
                <div className="hot-search-item" key={index}>
                  {`${index + 1}.${item}`}
                </div>
              );
            })}
          </div>
        </div>
      }
    </div>
  );
};

export default Search;