import React, { useEffect, useState, useRef } from 'react';
import EChartsReact from 'echarts-for-react';
import { useNavigate } from 'react-router-dom';
import { UndoOutline } from 'antd-mobile-icons'

import './index.css';
import { getCycle, getRelationChain } from './api';
import { join } from 'lodash';


const RelationChain = () => {

  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState({});
  const [cycleInfo, setCycleInfo] = useState([]);

  const navigate = useNavigate();

  const jump = (path) => {
    navigate(path);
  };

  const getUrlParams = (key) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    return params.get(key);
  };

  const createOption = (data) => {
    const currentCompanyId = getUrlParams('companyId');
    var currentCompany = {};
    
    const nodes = data.companyList.map((node,index) => {
      const companyId = node.id.toString();
      var size = 30
      if (companyId === currentCompanyId) {
        currentCompany = node;
        size = 50
      } 
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const category = letters[index % 26];

      return {
        id: node.id.toString(),
        name: node.companyFullName.match(/.{1,6}/g).join('\n'),
        symbolSize: size,
        category: category,
        value: node.companyFullName,
        label: {
          show: true
        }
      }
    });

    const links = data.relationList.map((link) => {
      let content =  "债务金额：" + link.price + "<br />" + 
                      "债权方：" + link.creditor.companyFullName + "<br />" + 
                      "债务方：" + link.debtor.companyFullName + "<br />" + 
                      "文书编号：" + link.wenshuNumber + "<br />" + 
                      "法院：" + link.courtName + "<br />" + 
                      "年份：" + link.year + "<br />" +  
                      // "<a href='"+ detailUrl + "'>查看债务详情</a><br />" + 
                      `<a id="viewDetail" creditorId=${link.creditor.id} debtorId=${link.debtor.id}>查看债务详情</a ><br />` + 
                      (link.courtUrl != null ? "<a href='" + link.courtUrl + "'>查看法院文书</a>" : "" )

    

      return {
        source: link.creditor.id.toString(),
        target: link.debtor.id.toString(),
        content: content,
        price: link.price,
        label: {
          show: true
        }
      }
    });

    let relationCount = data.relationList.length;
    let creditorCount = data.relationList.filter(item => item.creditor.id.toString() === currentCompanyId).length;
    let debtorCount = data.relationList.filter(item => item.debtor.id.toString() === currentCompanyId).length;
    
    return {
      title: {
        text: currentCompany.companyFullName + '关系链',
        subtext: '共找到' + relationCount.toString() + '条关系。 其中债务方:' + creditorCount.toString() + '个，债权方:' + debtorCount.toString() + '个',
        top: 'top',
        left: 'left'
      },
      tooltip: {
        formatter: function (params) {
          if (params.componentType === 'series') {
            if (params.dataType === 'node') {
              if (params.data.id === currentCompanyId) {
                return params.data.name;
              } else {
                let companyNodeContent = params.data.name + "<br />" +  
                      `<a id="viewCompanyDetail" companyId=${params.data.id}>点击查看</a ><br />`
                return companyNodeContent; // 自定义节点点击后的弹框内容信息
              }
            } else if (params.dataType === 'edge') {
              return '' + params.data.content; // 自定义边（连线）点击后的弹框内容信息
            }
          }
        },
        position: function (point, params, dom, rect, size) {
          var obj = {top: point[0]};
          let left = (size.viewSize[0] - size.contentSize[0])/2
          obj['left'] = left;
          obj['top'] = point[1] + 70;
          var element = document.getElementById('viewDetail');
          element?.addEventListener('click', () => {
            var creditorId = element.getAttribute('creditorid');
            var debtorId = element.getAttribute('debtorid');
            jump(`/detail?creditorId=${creditorId}&debtorId=${debtorId}`);
          });

          var otherCompanyElement = document.getElementById('viewCompanyDetail');
          otherCompanyElement?.addEventListener('click', () => {
            var companyId = otherCompanyElement.getAttribute('companyId');
            jump(`/relationChain?companyId=${companyId}`);
          });
          return obj;
        },
        enterable: true,
      },
      animationDuration: 1500,
      animationEasingUpdate: 'quinticInOut',
      series: [
        {
          selectedMode: 'single',
          select: {
            label: {
              show: true
            }
          },
          edgeLabel: {//边的设置
            show: true,
            position: "middle",
            fontSize: 12,
            formatter: (params) => {
              return params.data.price;
            },
          },
          edgeSymbol: ['none', 'arrow'],
          type: 'graph',
          layout: 'force',
          roam: false,
          lineStyle: {
            color: 'source',
            curveness: 0.2,
            join: 'miter'
          },
          emphasis: {
            scale: true,
            focus: 'adjacency',
            label: {
              show: true,
              position: 'top',
              fontSize: 20
            },
            edgeLabel: { 
              show: false,
            },lineStyle: { 
              width: 3,
            }
          },
          label: {
            show: true,
            position: 'middle',
            formatter: '{b}',
            borderWidth: 100
          },
          force : { //力引导图基本配置
            //initLayout: ,//力引导的初始化布局，默认使用xy轴的标点
            repulsion : 200,//节点之间的斥力因子。支持数组表达斥力范围，值越大斥力越大。
            gravity : 0.1,//节点受到的向中心的引力因子。该值越大节点越往中心点靠拢。
            edgeLength :[80,200],//边的两个节点之间的距离，这个距离也会受 repulsion。[10, 50] 。值越小则长度越长
            layoutAnimation : false //因为力引导布局会在多次迭代后才会稳定，这个参数决定是否显示布局的迭代动画，在浏览器端节点数据较多（>100）的时候不建议关闭，布局过程会造成浏览器假死。                         
          },
          autoCurveness: true,
          data: nodes,
          links: links,
          categories: [
            {
              "name": "A"
            },
            {
              "name": "B"
            },
            {
              "name": "C"
            },
            {
              "name": "D"
            },
            {
              "name": "E"
            },
            {
              "name": "F"
            },
            {
              "name": "G"
            },
            {
              "name": "H"
            },
            {
              "name": "I"
            },
            {
              "name": "J"
            },
            {
              "name": "K"
            },
            {
              "name": "L"
            },
            {
              "name": "M"
            },
            {
              "name": "N"
            },
            {
              "name": "O"
            },
            {
              "name": "P"
            },
            {
              "name": "Q"
            },
            {
              "name": "R"
            },
            {
              "name": "S"
            },
            {
              "name": "T"
            },
            {
              "name": "U"
            },
            {
              "name": "V"
            },
            {
              "name": "W"
            },
            {
              "name": "X"
            },
            {
              "name": "Y"
            },
            {
              "name": "Z"
            }
          ]
        }
      ]
    }
   };

  useEffect(() => {
    const companyId = getUrlParams('companyId');
    const level = 1;
    const params = { "companyId": companyId , "level": level}
    getRelationChain(params).then(res => {
      const option = createOption(res.data);
      setOption(option);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })

    getCycle(params).then(res => {
      setCycleInfo(res.data);
    }).catch(err => {
    })
  }, []);


  return (<div className="relation">
    <UndoOutline className='refresh' onClick={() => { window.location.reload(); }} />
    { loading ? <div className="loading">加载中...</div> : ''}
    { option === null ? '' : <EChartsReact option={option} style={{ height: 'calc(100vh - 55px - 30px - 80px - 42px)' }}/>}
    { cycleInfo.length > 0 ? <div className="search-result-help" onClick={() => { jump('/relationLoop?companyId=' + getUrlParams('companyId') ); }}>共找到{cycleInfo.length}条闭环数据，点击查看</div> : ''}
    <div className="search-result-help" onClick={() => { jump('/create'); }}>我要上链</div>
  </div>)
};

export default RelationChain;