import { useNavigate } from 'react-router-dom';
import { SearchBar } from 'antd-mobile'
import { data } from './data';

import './index.css';

const Home = () => {
  const navigate = useNavigate();

  const jump = (path) => {
    navigate(path);
  };

  return (
    <div className="home">
      <div className="home-top">
        <div className="home-top-name">
          <div className="home-top-name-text">查查查V1.0.5</div>
          <div className="separate" />
          <div className="home-top-name-desc">全国企业信息查询系统官方备案企业征信机构</div>
        </div>
        <div className="home-search">
          <SearchBar
            placeholder="请输入企业名等关键字进行搜索"
            onFocus={() => { jump('/search'); }}
          />
        </div>
        <div className="home-link" onClick={() => { jump('/create'); }}>我要上链</div>
      </div>
      <div className="home-content">
        {
          data.map((item, index) => {
            return <div className="home-content-item" key={index}>
              <div className="home-content-item-time">{item.time}</div>
              <div className="home-content-item-title">{item.title}</div>
              <div className="home-content-item-desc">{item.desc}</div>
              <div className="home-content-item-boder">
                <div className="round-dot" />
                <div className="round-border" />
              </div>
            </div>
          })
        }
      </div>
    </div>
  );
}

export default Home