import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LeftOutline } from 'antd-mobile-icons';

import RouteListen from './components/routeListen';
import Home from './page/home';
import Search from './page/search';
import Create from './page/create';
import Detail from './page/detail';
import SearchResult from './page/searchResult';
import RelationChain from './page/relationChain';
import RelationLoop from "./page/relationLoop";

import './App.css';

const App = () => {
  const [pathname, setPathname] = useState(window.location.pathname);

  return (
    <div className="App">
      <div className="app-title">
        {
          pathname !== '/' && pathname !== '/home' && <LeftOutline className="app-back"  onClick={() => { window.history.back(); }} />
        }
        <div>查查查</div>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<RouteListen setPathname={setPathname}><Home /></RouteListen>} />
          <Route path='/home' element={<RouteListen setPathname={setPathname}><Home /></RouteListen>} />
          <Route path='/search' element={<RouteListen setPathname={setPathname}><Search /></RouteListen>} />
          <Route path='/create' element={<RouteListen setPathname={setPathname}><Create /></RouteListen>} />
          <Route path='/detail' element={<RouteListen setPathname={setPathname}><Detail /></RouteListen>} />
          <Route path='/relationChain' element={<RouteListen setPathname={setPathname}><RelationChain /></RouteListen>} />
          <Route path='/searchResult' element={<RouteListen setPathname={setPathname}><SearchResult /></RouteListen>} />
          <Route path='/relationLoop' element={<RouteListen setPathname={setPathname}><RelationLoop /></RouteListen>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
