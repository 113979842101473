import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Toast,
  Dialog
} from 'antd-mobile'
import { DeleteOutline } from 'antd-mobile-icons'
import { upload, uploadGraph } from './api';

import './index.css';

const Create = () => {
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();

  const onFinish = (values) => {
    // 文件地址&上传文件都为空
    if (!values.courtUrl && fileList?.length === 0) {
      Toast.show({
        content: '文件地址和上传文件必须填写一项'
      });
      return;
    }
    const params = {
      ...values,
      price: Math.round(Number(values.price))
    };
    if (fileList?.length > 0) {
      params.picToken = fileList[0].fileKey
    }
    uploadGraph(params).then((data) => {
      if (data.code !== 200) {
        const msg = '上传失败，请联系管理员。失败原因：' + data.message;
        Dialog.alert({
          content: msg,
        })
      } else {
        Dialog.alert({
          content: '信息上传成功，待审核完成即可查看',
          onConfirm: () => {
            navigate('/');
          },
        })
      }
    });
  };

  const uploadFile = (value) => {
    const files = value?.target?.files
    if (files?.length > 0) {
      const file = files[0];
      const size = bytesToMB(file.size);
      if (size > 10) {
        Toast.show({
          content: '文件不能大于10M'
        });
        return;
      }
      let formatData = new FormData();
      formatData.append('file', file);
      upload(formatData).then((res) => {
        setFileList([...fileList, { name: file.name, fileKey: res.data.token }]);
      })
    }
  };

  const deteleFile = (fileKey) => {
    const newFileList = fileList.filter((item) => item.fileKey !== fileKey);
    setFileList(newFileList);
  };

  const bytesToMB = (bytes) => {
    return (bytes / 1024 / 1024).toFixed(2);
  }

  const UploadTitleLabel = () => (
    <label>
      <label className="red-text">*</label>
      <label className="text-normal">上传文件</label>
    </label>
  );

  const validateInteger = (rule, value, callback) => {
    if (value && !Number.isInteger(Number(value))) {
      callback('请输入整数金额');
    } else {
      callback();
    }
  };

  return (
    <div className="create">
      <div className="create-title">上链信息提交</div>
      <Form
        onFinish={onFinish}
         footer={
          <Button block type='submit' color='primary' size='large'>
            提交
          </Button>
        }
      >
        <Form.Item
          name='creditor'
          label='债权公司名称'
          rules={[{ required: true, message: '请输入债权公司名称' }]}
        >
          <Input placeholder='请输入债权公司名称' />
        </Form.Item>
        <Form.Item
          name='debtor'
          label='债务公司名称'
          rules={[{ required: true, message: '请输入债务公司名称' }]}
        >
          <Input placeholder='请输入债务公司名称' />
        </Form.Item>
        
        <Form.Item
          name='price'
          label='金额'
          rules={[{ required: true, message: '请输入整数金额' }]}
        >
          <Input type="number" placeholder='请输入整数金额' />
        </Form.Item>
        <Form.Item
          name='courtUrl'
          label='请输入文书地址'
        >
          <Input placeholder='请输入文书地址' />
        </Form.Item>
        <Form.Item
          name='picToken'
          label={<UploadTitleLabel />}
        >
          <div className="upload">
            
            {
              fileList?.length === 0 && <>
                <div className="upload-btn">上传文件</div>
                <input
              onChange={(value) => { uploadFile(value);  }} className="upload-input" type='file' />
              </>
             
            }
            
            <div className="file-list">
              {
                fileList?.map((item, index) => {
                  return <div className="file-list-item" key={index}>
                    <div className="file-item-name">{item.name}</div>
                    <DeleteOutline onClick={() => { deteleFile(item.fileKey) }} />
                  </div>
                })
              }
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Create;