import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBar } from 'antd-mobile';
import { debounce } from 'lodash';
import { getSearchResult } from './api';

import './index.css';

const SearchResult = () => {
  const [page, setPage] = useState({
    currentPage: 0,
    pages: 1
  });
  const [loading, setLoading] = useState(false);
  const [resultList, setResultList] = useState([]);
  const navigate = useNavigate();
  const searchRef = useRef(null)


  useEffect(() => {
    const value = getUrlParams('keyword');
    getPageList({
      currentPage: 0,
      keyword: value
    });
  }, []);

  const jump = (path) => {
    navigate(path);
  };

  const getUrlParams = (key) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    return params.get(key);
  };

  const viewDetail = (id) => {
    jump(`/relationChain?companyId=${id}`);
  }


  const renderResult= () => {
    if (resultList?.length > 0) {
      return resultList.map((item) => {
        return <div className="search-result-item" key={item.id}>
          <div className="search-result-item-name">{item.companyFullName}</div>
          <div>债务方： {item.debtorCount}位</div>
          <div>债权方： {item.creditorCount}位</div>
          <div className="view-detail" onClick={() => { viewDetail(item.id); }}>查看详情</div>
        </div>
      })
    }
    return <div className="search-result-empty">
      <div onClick={() => { jump('/create'); }}>
         {getValue() ? `未找到${getValue()},点击上链` : ''}
      </div>
    </div>
  };

  const getValue = () => {
    return searchRef.current?.nativeElement?.value || '';
  }

  const getPageList = (params) => {
    const pageState = page.currentPage === 1 ? true : page.currentPage < page.pages;
    if (!loading && pageState) {
      setLoading(true);
      getSearchResult({
        keyword: params.keyword,
        page: params.currentPage,
        pageSize: 10
      }).then((res) => {
        const list = res.data?.companyList || [];
        setResultList(params.currentPage === 0 ? list : resultList.concat(list));
        setPage({
          currentPage: res.data.page  + 1 || 0,
          pages: Math.ceil(res.data.totalSize / 10) || 1
        });
        setLoading(false);
        setNewUrl();
      }).catch(() => {
        setLoading(false);
      });
    }
  };

  const searchHandler = debounce((value) => {
    if (value) {
      getPageList({
        currentPage: 0,
        keyword: value
      });
    }
  }, 500);

  const searchClick = () => {
    let key = getValue();
    searchHandler(key);
  }

  const setNewUrl = () => { 
    let key = getValue();
    let urlKey = getUrlParams('keyword');
    if (key === urlKey) {
      return;
    }
    // 获取当前URL的查询字符串对象
    const searchParams = new URLSearchParams(window.location.search);

    // 修改或添加参数
    searchParams.set('keyword', key); // 更新已存在的参数

    // 构建新的URL
    const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;

    // 使用History API更新URL
    window.history.replaceState({}, '', newUrl);
  }


  const onScroll = () => {
    const listDom = document.getElementsByClassName('search-result-content')[0];
    if (listDom) {
      let scrollHeight = listDom.scrollHeight;
      // 变量 windowHeight 是可视区的高度
      let windowHeight = listDom.clientHeight
      // 变量scrollTop为当前页面的滚动条纵坐标位置
      let scrollTop = listDom.scrollTop;
      // 滚动条到底部得距离 = 滚动条的总高度 - 可视区的高度 - 当前页面的滚动条纵坐标位置
      let scrollBottom = scrollHeight - windowHeight - scrollTop;
      if (scrollBottom < 250) {
        getPageList({
          currentPage: page.currentPage,
          keyword: getValue()
        });
      }
    }
  };

  return (
    <div className="search-result">
      <div className="search-result-top">
        <SearchBar
          ref={searchRef}
          defaultValue={getUrlParams('keyword')}
          className="search-input"
          placeholder="请输入企业名等关键字进行搜索"
        />
         <div className="search-btn" onClick={() => { searchClick() }}>搜索</div>
      </div>
      <div className="search-result-content" onScroll={onScroll}>
        {
          renderResult()
        }
      </div>
    </div>
  )
};

export default SearchResult;