import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteListen = (props) => {
  const location = useLocation();

  useEffect(() => {
    props.setPathname(location.pathname)
  }, [location.pathname])
  
  return <div>
    {props.children}
  </div>
}

export default RouteListen;