export const data = [{
  time: '21:00',
  title: '宣城两国启动商品房”以旧换新“',
  desc: '日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和',
}, {
  time: '21:00',
  title: '宣城两国启动商品房”以旧换新“',
  desc: '日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和',
}, {
  time: '21:00',
  title: '宣城两国启动商品房”以旧换新“',
  desc: '日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和日前，安徽宣城市城建集团和',
}];